*{margin: 0;padding: 0;box-sizing: border-box;}
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import "ColorPalette.css";

 
 /* Navbar*/
.header_nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100vw;
    height: 6vh;
    min-height: 40px;
    background-color:var(--lightblue);
    margin-bottom: 8px;
  }
 
 /* SignOutButton.css */
 .navbar-container {
    position: relative; 
    width:auto;
    height: 70%;
    min-width: 130px;
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid var(--borderC);
    box-shadow: 0px 3px 2px 0px #1a1d42;
    margin: 0.1% 1%;
    background-color: var(--lightblue);
  }

  .navbar-btn {
    width:100%;
    height: 100%;
    border-radius: 5px;
    background-color:#3B4191;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border: none;
    outline: none;
  }

.icono-logout {
  margin: 5%;
  width: 15%; 
  height: auto;  
}

.text {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.text h2 {
  font-family: "Open Sans";
  font-size: 70%;
  font-weight: 700;
  color: white;
}

.arrow{
  width: 5%; 
  height: auto; 
  margin: 3% 5% 0% 4%;
  }
  
  .navbar-btn:hover {
    background-color: #515BDC;
  }
  
  .navbar-btn:focus {
    outline: none;
    box-shadow: 0 0 0 2px #898ECA;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0; 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 7% 5%;
    gap:3%;
    background: var( --darkblue);
    box-shadow: 0 0.25rem 0.3rem rgba(0, 0, 0, 0.25); 
    z-index: 1000; 
  }
  
  .dropdown-item {
    padding: 5%; 
    font-size: 70%;
    text-align: left; 
    background: transparent; 
    color: white;
    border: none;
    width: 100%;
    gap: 5%;
    display: flex;
    align-items: center;
       
  }

  
  .dropdown-item:hover {
    background-color: #3B4189;
  }
  
  .logout-icon {
    margin-right: 8px;
    font-size: 1.3em; 
    vertical-align: middle;  }