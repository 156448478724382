@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.GraficaP {
    display: flex;
    justify-content: center; /* Centrar la gráfica horizontalmente */
    align-items: center; /* Centrar la gráfica verticalmente */
    width: 100%; /* Ajustar el ancho al 96% para dejar un margen del 2% */
    height: 100%; /* Ajustar la altura al 88vh para dejar un margen del 2% del encabezado */
    transition: 0.3s;
    padding-right: 0.5%;
     /* Añadir margen superior e inferior del 2% y centrar horizontalmente */
    background-color: blue;
  }
  
  .Tablero {
    width: 100%;
    height: 100%;
    transition: 0.3s;
    border: none;
    overflow: hidden;
    /*background-color: blue; border:1px solid black;*/
    /*border:1px solid black;*/
  }
  
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Estilo global para asegurar que no haya bordes en el componente embebido */
  .GraficaP {
    border: none;
    /*border:1px solid red;*/
  }
  
  .Tablero iframe {
    border: none !important;
  
  }
  
  .embeded {
    font-family: "Open Sans";
    width: 300px;
    margin: 0 auto; /* Centrar el contenedor horizontalmente */
    padding: 20px;
    background-color: #f8f9fa; /* Un fondo claro para el contenedor */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Sombra sutil para destacar el contenedor */
    border-radius: 8px; /* Bordes redondeados para un diseño más suave */
    text-align: left; /* Alineación del texto a la izquierda */
    overflow: hidden; /* Evita que el contenido desborde el contenedor */
    word-wrap: break-word; /* Asegura que las palabras largas se rompan y pasen a la siguiente línea */
}

.embeded h1 {
    font-family: "Open Sans";
    color: #333; /* Color oscuro para el título para mayor legibilidad */
    margin-bottom: 10px; /* Espacio debajo del título */
    font-size: 20px; /* Tamaño de fuente más grande para el título */
}

.embeded p {
    font-family: "Open Sans";
    color: #666; /* Un color más suave para el texto del párrafo */
    line-height: 1.5; /* Espaciado de línea para mejorar la legibilidad */
    font-size: 14px; /* Tamaño de fuente adecuado para lectura */
    margin-bottom: 5px; /* Espacio entre párrafos */
}

