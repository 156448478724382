@import "Auth.css";
@import "Sidebar.css";
@import "Navar.css";
@import "Content.css";
@import "Powerbi.css";
@import "Graph.css";
.elementConScroll {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

/* Estilos comunes para .icono */
.icono {
  width: 100%;
  height: auto;
  margin: 10%;
  padding: 5%;
}

.icono.expandido {
  width: 80%;
}

/* Estilos para pantallas pequeñas (máx-width: 600px) */
@media (max-width: 600px) {
  .sidebar {
    width: calc(var(--sidebar-width-Expand) - 100px);
    padding: 2%;
  }
  .Company {
    font-size: 70%;
  }
  .Program {
    font-size: 50%;
  }
  .sidebar.collapsed ul li a {
    color: var(--notselected);
  }
  .sidebar.collapsed ul li a svg {
    font-size: 110%;
    color: var(--notselected);
  }
  .sidebar.collapsed ul li.active a {
    padding: 3% 20%;
  }
  .sidebar.collapsed ul li.active a svg {
    font-size: 180%;
    color: var(--lightblue);
  }
  .sidebar ul li span.TextOptions,
  .sidebar ul li.active span.TextOptions {
    font-size: 60%;
  }
  .sidebar ul li a svg {
    font-size: 80%;
    color: var(--notselected);
  }
  .sidebar ul li.active a svg {
    font-size: 80%;
    color: var(--lightblue);
  }
}

/* Estilos para pantallas medianas (mín-width: 601px y máx-width: 900px) */
@media (min-width: 601px) and (max-width: 900px) {
  .sidebar {
    padding: 2%;
  }
  .Company {
    font-size: 90%;
  }
  .Program {
    font-size: 70%;
  }
  .sidebar ul li span.TextOptions,
  .sidebar ul li.active span.TextOptions {
    font-size: 80%;
  }
  .sidebar ul li a svg,
  .sidebar ul li.active a svg {
    font-size: 105%;
  }
  .icono.expandido {
    width: 70%;
  }
}

/* Estilos para pantallas grandes (mín-width: 901px y máx-width: 1400px) */
@media (min-width: 901px) and (max-width: 1400px) {
  .sidebar {
    padding: 1.5%;
  }
  .Company {
    font-size: 110%;
  }
  .Program {
    font-size: 80%;
  }
  .sidebar ul li span.TextOptions,
  .sidebar ul li.active span.TextOptions {
    font-size: 80%;
  }
  .sidebar ul li a svg,
  .sidebar ul li.active a svg {
    font-size: 110%;
  }
  .sidebar.collapsed ul li a svg {
    font-size: 170%;
  }
  .sidebar.collapsed ul li.active a {
    padding: 5% 20%;
  }
  .sidebar.collapsed ul li.active a svg {
    font-size: 250%;
  }
  .icono.expandido {
    width: 70%;
  }
}

/* Estilos para pantallas muy grandes (mín-width: 1401px y máx-width: 1990px) */
@media (min-width: 1401px) and (max-width: 1990px) {
  .sidebar {
    padding: 0.8%;
  }
  .Company {
    font-size: 100%;
  }
  .Program {
    font-size: 70%;
  }
  .sidebar ul li span.TextOptions,
  .sidebar ul li.active span.TextOptions {
    font-size: 80%;
  }
  .sidebar ul li a svg,
  .sidebar ul li.active a svg {
    font-size: 100%;
  }
  .sidebar.collapsed ul li a svg {
    font-size: 150%;
  }
  .sidebar.collapsed ul li.active a {
    padding: 5% 20%;
  }
  .sidebar.collapsed ul li.active a svg {
    font-size: 200%;
  }
}

/* Estilos para pantallas extra grandes (mín-width: 1991px) */
@media (min-width: 1991px) {
  .sidebar {
    padding: 0.8%;
  }
  .Company {
    font-size: 100%;
  }
  .Program {
    font-size: 70%;
  }
  .sidebar ul li span.TextOptions,
  .sidebar ul li.active span.TextOptions {
    font-size: 80%;
  }
  .sidebar ul li a svg,
  .sidebar ul li.active a svg {
    font-size: 100%;
  }
  .sidebar.collapsed ul li a svg {
    font-size: 150%;
  }
  .sidebar.collapsed ul li.active a {
    padding: 5% 20%;
  }
  .sidebar.collapsed ul li.active a svg {
    font-size: 200%;
  }
}

/* Estilos para pantallas en orientación horizontal con altura máxima de 360px */
@media (max-height: 360px) and (orientation: landscape) {
  .distribution{
    height:86vh;
  }
  .sidebar {
    padding: 2%;
  }
  .Nameproduct {
    margin: 5% 2% 2% 2%;
  }
  .Company {
    font-size: 80%;
  }
  .Program {
    font-size: 60%;
  }
  .sidebar ul li span.TextOptions,
  .sidebar ul li.active span.TextOptions {
    font-size: 70%;
  }
  .sidebar ul li a {
    padding: 4% 0% 4% 5%;
    margin-top: 10%;
  }
  .sidebar ul li a svg,
  .sidebar ul li.active a svg {
    font-size: 90%;
  }
  .sidebar.collapsed ul li a svg {
    font-size: 120%;
  }
  .sidebar.collapsed ul li.active a {
    padding: 3% 20%;
  }
  .sidebar.collapsed ul li.active a svg {
    font-size: 190%;
  }
  .mi-clase-icono.colapsado {
    padding-top: 10%;
    margin: 2%;
  }
  .mi-clase-icono.expandido {
    padding-top: 10%;
    margin-top: 25%;
  }
  .menu-icon {
    font-size: 1rem;
  }
}

/* Estilos para pantallas en orientación horizontal con altura entre 361px y 640px */
@media (min-height: 361px) and (max-height: 640px) and (orientation: landscape) {
  .distribution{
    height:87vh;
  }
  .sidebar {
    padding: 2%;
  }
  .Nameproduct {
    margin: 5% 2% 2% 2%;
  }
  .Company {
    font-size: 90%;
  }
  .Program {
    font-size: 70%;
  }
  .sidebar ul li span.TextOptions,
  .sidebar ul li.active span.TextOptions {
    font-size: 80%;
  }
  .sidebar ul li a {
    padding: 4% 0% 4% 5%;
    margin-top: 10%;
  }
  .sidebar ul li a svg,
  .sidebar ul li.active a svg {
    font-size: 100%;
  }
  .sidebar.collapsed ul li a svg {
    font-size: 120%;
  }
  .sidebar.collapsed ul li.active a {
    padding: 3% 20%;
  }
  .sidebar.collapsed ul li.active a svg {
    font-size: 190%;
  }
  .mi-clase-icono.colapsado {
    padding-top: 10%;
    margin: 2%;
  }
  .mi-clase-icono.expandido {
    padding-top: 7%;
    margin-top: 25%;
  }
  .menu-icon {
    font-size: 1.1rem;
  }
}
